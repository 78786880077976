import React from "react"
import { graphql, Link } from "gatsby"
import { GatsbyImage, getImage, StaticImage } from "gatsby-plugin-image"
import styled from "styled-components/macro"

import Container from "../common/Container"
import PromoVideos from "../PromoVideos"
import Gallery from "../Gallery"
import LogoIcon from "../../images/logo-icon.svg"
import Seo from "../seo"
import { pxToRem } from "../../utils"
import Layout from "../layout"
import { useSiteMeta } from "../../hooks/siteMeta"
import Contact from "../sections/main/Contact"

const ActPageLayout = ({
  data: {
    page: { frontmatter, html },
  },
}) => {
  const { siteUrl } = useSiteMeta()
  const { title, video, cover, promoVideos, gallery } = frontmatter
  return (
    <Layout>
      <Hero>
        <Container>
          <HeroTitle className="wow animate__fadeIn">{title}</HeroTitle>
        </Container>
        <VideoBackground>
          <Video
            autoPlay
            muted
            loop
            playsInline
            poster={cover.publicURL}
            preload="metadata"
          >
            <source src={video.publicURL} type="video/mp4" />
          </Video>
        </VideoBackground>
        <StaticImage
          src="../../images/mask-top.png"
          alt="Flip Unit Airtrack Show"
          style={{ position: "absolute", bottom: -2, left: 0, right: 0 }}
        />
      </Hero>
      <Teaser>
        <Container>
          <TeaserLogoIconWrapper className="wow animate__fadeIn">
            <TeaserLogoIcon />
          </TeaserLogoIconWrapper>
          <TeaserDescription
            className="wow animate__fadeIn"
            data-wow-delay=".2s"
            dangerouslySetInnerHTML={{ __html: html }}
          />
          <PromoVideos promoVideos={promoVideos} />
          <Gallery gallery={gallery} url={siteUrl} />
        </Container>
        <StaticImage
          src="../../images/mask-bottom.png"
          alt="Flip Unit Airtrack Show"
          style={{
            position: "absolute",
            bottom: 1,
            left: 0,
            right: 0,
            transform: "translateY(100%)",
          }}
        />
      </Teaser>
      <Contact style={{ padding: `${pxToRem(200)} 0` }} />
    </Layout>
  )
}

export default ActPageLayout

export const Head = ({
  data: {
    page: { frontmatter },
  },
}) => {
  return (
    <Seo
      title={frontmatter.seoTitle}
      description={frontmatter.seoMetaDescription}
      ogImage={frontmatter.cover.src.resize.src}
      slug={frontmatter.slug}
    />
  )
}

export const pageQuery = graphql`
  query ($id: String) {
    page: markdownRemark(id: { eq: $id }) {
      id
      html
      frontmatter {
        title
        slug
        seoTitle
        seoMetaDescription
        cover {
          src: childImageSharp {
            gatsbyImageData(formats: [WEBP])
            resize(width: 900) {
              src
            }
          }
        }
        video {
          publicURL
        }
        promoVideos
        gallery {
          imageAlt
          image {
            lightbox: childImageSharp {
              gatsbyImageData(formats: [WEBP])
              resize(width: 900) {
                src
              }
            }
          }
        }
      }
    }
  }
`
const Hero = styled.section`
  position: relative;
  height: calc(100vh - 85px);
  text-align: center;

  & ${Container} {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    padding-bottom: ${pxToRem(70)};

    @media all and (min-width: 1281px) {
      padding-bottom: ${pxToRem(100)};
    }
  }
`
const HeroTitle = styled.h1`
  font-family: "Teko", sans-serif;
  font-size: ${pxToRem(80)};
  font-weight: 700;
  text-transform: uppercase;
  margin: 0;
  margin-bottom: ${pxToRem(20)};
  line-height: 0.85;

  @media all and (min-width: 821px) {
    font-size: ${pxToRem(90)};
  }
  @media all and (min-width: 1281px) {
    font-size: ${pxToRem(110)};
  }
`
const HeroAltTitle = styled.h2`
  font-family: "Montserrat", sans-serif;
  font-size: ${pxToRem(30)};
  font-weight: 700;
  margin: 0;
  line-height: 1.2;

  @media all and (min-width: 1281px) {
    font-size: ${pxToRem(40)};
  }
`
const VideoBackground = styled.div`
  height: inherit;
  width: 100%;
  position: absolute;
  z-index: -100;
  top: 0;
  left: 0;
`
const Video = styled.video`
  height: 100%;
  left: 0;
  object-fit: cover;
  position: absolute;
  top: 0;
  width: 100%;
`
const Teaser = styled.section`
  position: relative;
  padding: ${pxToRem(60)} 0;
  background: linear-gradient(
    90deg,
    rgba(4, 4, 21, 1) 0%,
    rgba(4, 4, 21, 1) 26.46%,
    rgba(12, 12, 74, 1) 80.42%,
    rgba(0, 0, 0, 1) 100%,
    rgba(0, 0, 0, 1) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#040415',endColorstr='#000000' , GradientType=1);

  & ${Container} {
    max-width: 960px;

    @media all and (min-width: 1400px) {
      max-width: 1200px;
    }
  }
`
const TeaserLogoIconWrapper = styled.div`
  margin: 0 auto ${pxToRem(100)} auto;
  display: flex;
  align-items: center;
  justify-content: center;
`
const TeaserLogoIcon = styled(LogoIcon)`
  color: #fff;
  width: 40px;
  display: block;

  & path {
    fill: #fff;
  }
`
const TeaserDescription = styled.div`
  font-weight: 300;
  font-size: ${pxToRem(28)};
  text-align: center;
  padding: 0 ${pxToRem(20)};
  max-width: 1200px;
  margin: 0 auto ${pxToRem(40)} auto;

  @media all and (min-width: 821px) {
    margin: 0 auto ${pxToRem(130)} auto;
  }
`
