import React, { useState } from "react"
import styled from "styled-components"
import ReactBnbGallery from "react-bnb-gallery"

const Image = styled.div`
  max-width: 600px;
  height: 250px;
  background-color: transparent;
  position: relative;
  background-image: ${props => `url(${props.bg})`};
  background-position: center;
  background-size: cover;
  clip-path: polygon(
    0 0,
    100% 0,
    100% calc(100% - 10px),
    calc(100% - 10px) 100%,
    0 100%
  );
  transition: all 0.2s ease-in-out;
  cursor: pointer;

  &:before {
    position: absolute;
    display: block;
    content: "";
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    clip-path: polygon(
      0 0,
      100% 0,
      100% calc(100% - 10px),
      calc(100% - 10px) 100%,
      0 100%,
      0 2px,
      2px 2px,
      2px calc(100% - 2px),
      calc(100% - 11px) calc(100% - 2px),
      calc(100% - 2px) calc(100% - 11px),
      calc(100% - 2px) 2px,
      0 2px
    );
    z-index: -1;
    opacity: 1;
  }

  &:hover {
    transform: scale(1.03);
  }
  @media all and (min-width: 768px) {
    grid-column: span 2;
  }
`
const GalleryContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 1rem;
  margin-bottom: 3rem;
  background-color: #6c9fff;
  padding: 1rem;

  @media all and (min-width: 768px) {
    margin-bottom: 5rem;
    grid-template-columns: repeat(6, 1fr);

    & ${Image}:first-child {
      grid-column: span 3;
    }

    & ${Image}:nth-child(2) {
      grid-column: span 3;
    }
  }
`

const Gallery = ({ gallery, url }) => {
  const [isOpen, setIsOpen] = useState(false)
  const [startingImageId, setStartingImageId] = useState(0)
  const toggleIsOpen = (id = 0) => {
    setStartingImageId(id)
    setIsOpen(!isOpen)
  }
  
  if (!gallery) return null

  const images = gallery.map((e, idx) => ({
    photo: `${url}${e.image?.lightbox.resize.src}`,
    number: idx,
    thumbnail: `${url}${e.image?.lightbox.resize.src}`,
    caption: e.imageAlt,
  }))
  return (
    <>
      <GalleryContainer>
        {gallery.slice(0, 5).map((e, idx) => (
          <Image
            key={idx}
            bg={e.image?.lightbox.resize.src}
            title={e.imageAlt}
            onClick={() => toggleIsOpen(idx)}
            className="wow fadeIn"
            data-wow-delay={`${(idx * 2) / 10}s`}
          />
        ))}
      </GalleryContainer>
      <ReactBnbGallery
        activePhotoIndex={startingImageId}
        show={isOpen}
        photos={images}
        onClose={toggleIsOpen}
      />
    </>
  )
}

export default Gallery
