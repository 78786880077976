import { useStaticQuery, graphql } from "gatsby"

export const useSiteMeta = () => {
  const {
    site: { siteMetadata },
  } = useStaticQuery(
    graphql`
      query SiteMeta {
        site {
          siteMetadata {
            siteUrl
          }
        }
      }
    `
  )

  return {
    ...siteMetadata,
  }
}
